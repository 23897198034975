var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"form-overflow"},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"a"},[_c('span',{staticClass:"a",on:{"click":function($event){return _vm.$router.push('/portrait/performance')}}},[_vm._v("绩效设置")]),_vm._v(" "),_c('span',{staticClass:"b"},[_vm._v("/")]),_vm._v(" "),_c('span',{staticClass:"s"},[_vm._v("设置")])])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"head"},[_vm._v("产能绩效")]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"150px"}},[_c('el-form-item',{attrs:{"prop":"criticalValue","label":"产能临界值"}},[_c('el-input',{staticClass:"ipt",attrs:{"type":"number","min":"0"},on:{"input":function (val) {
                _vm.iptChange(val, 'a');
              }},model:{value:(_vm.form.criticalValue),callback:function ($$v) {_vm.$set(_vm.form, "criticalValue", $$v)},expression:"form.criticalValue"}}),_c('span',{staticClass:"y"},[_vm._v("元")])],1),_c('el-form-item',{attrs:{"prop":"basicCommission","label":"客服基础提成系数"}},[_c('el-input',{staticClass:"ipt",attrs:{"type":"number","min":"0"},on:{"input":function (val) {
                _vm.iptChange(val, 'b');
              }},model:{value:(_vm.form.basicCommission),callback:function ($$v) {_vm.$set(_vm.form, "basicCommission", $$v)},expression:"form.basicCommission"}})],1),_c('el-form-item',{attrs:{"prop":"","label":"提成区间"}},[_vm._l((_vm.form.detailList),function(item,i){return _c('div',{key:i},[(item.deleteFlag != 1)?[_c('el-input',{staticClass:"ipt",attrs:{"disabled":""},model:{value:(item.startPrice),callback:function ($$v) {_vm.$set(item, "startPrice", $$v)},expression:"item.startPrice"}}),_c('span',{staticClass:"zhi"},[_vm._v("至")]),_c('el-input',{staticClass:"ipt",attrs:{"type":"number","min":"0"},on:{"change":function (val) {
                    _vm.changeNum(val, i);
                  },"input":function (val) {
                    _vm.iptChange(val, 'c', i);
                  }},model:{value:(item.endPrice),callback:function ($$v) {_vm.$set(item, "endPrice", $$v)},expression:"item.endPrice"}}),_c('span',{staticClass:"xi"},[_vm._v("超临界值区间系数")]),_c('el-input',{staticClass:"ipt",attrs:{"type":"number","min":"0"},on:{"input":function (val) {
                    _vm.iptChange(val, 'd', i);
                  }},model:{value:(item.advancedCommission),callback:function ($$v) {_vm.$set(item, "advancedCommission", $$v)},expression:"item.advancedCommission"}}),(i != 0)?_c('el-button',{staticClass:"del",attrs:{"icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.del(i)}}}):_vm._e()]:_vm._e()],2)}),_c('el-button',{staticClass:"cp",attrs:{"plain":"","icon":"el-icon-plus"},on:{"click":function () {
                _vm.addList();
              }}},[_vm._v("新增区间")])],2)],1)],1)]),_vm._m(0),_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"btn-content"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":function () {
            _vm.$router.push('/portrait/performance');
          }}},[_vm._v("取消")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {
            _vm.addSet();
          }}},[_vm._v("确定")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-tip"},[_c('div',[_vm._v("【产能绩效计算】"),_c('br'),_vm._v(" "),_c('br')]),_c('div',[_vm._v(" 当实际产能≤产能临界值时，客服当月绩效=当月产能值*客服基础提成系数"),_c('br'),_vm._v(" 当实际产能＞产能临界值时，客服当月绩效=产能临界值*客服基础提成系数+（当月产能-产能临界值）*客服基础提成系数*超临界值区间系数 ")])])}]

export { render, staticRenderFns }